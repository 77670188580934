import {useGlobalStore} from "@/stores/global";
import AlertDialogPopupModel from "@/models/AlertDialogPopupModel";
import {AlertDialogDataInterface} from "@/models/interfaces/AlertDialogDataInterface";
import {ApplicationException} from "@/models/exceptions/ApplicationException";

export function useDialog() {
    const store = useGlobalStore();
    const showDialog = (title: string, message: string | null, actions: Array<{name: string, action: (data: AlertDialogDataInterface) => void}> | null) => {
        store.alertMessage = new AlertDialogPopupModel(title, message, actions);
    };
    const dialogWhenApplicationError = (e: Error | any, showAlert: boolean = false) => {
        if (e instanceof ApplicationException) {
            showDialog('Error', e.message, []);
        } else {
            if (showAlert) {
                alert(e);
            }
        }
    };
    const closeDialog = store.alertMessage == null;
    return { showDialog, dialogWhenApplicationError, closeDialog };
}
