import {Attachment} from '@/models/Attachment';
import {Portfolio} from "@/models/Portfolio";
import moment from "moment/moment";
import {User} from '@/models/User';

export class Post {
    id: number | null;
    attachments: Attachment[];
    title: string;
    description: string;
    postDate: Date;
    eventDate: Date;
    type: string;
    location: string | null;

    comments: string[];
    portfolio: Portfolio | null;
    author: User | null;

    constructor(
        id: number | null,
        attachments: Attachment[],
        title: string, description: string,
        eventDate: Date,
        postDate: Date, postType: string,
        comments: string[],
        location: string | null = null,
        portfolio: Portfolio | null = null,
        author: User | null = null,
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.eventDate = eventDate;
        this.postDate = postDate;
        this.type = postType;
        this.comments = comments;
        this.attachments = attachments;
        this.location = location;
        this.portfolio = portfolio;
        this.author = author;
    }

    static empty(): Post {
        const date = moment().toDate();
        return new Post(null, [], '', '', date, date, '', []);
    }

    public get imageAttachments(): Attachment[]
    {
        const imageAttachments: Attachment[] = this.attachments.filter((attachment) => {
            return attachment.isImage;
        });

        return imageAttachments;
    }

    public get videoAttachments(): Attachment[]
    {
        const videoAttachments: Attachment[] = this.attachments.filter((attachment) => {
            return attachment.isVideo;
        });

        return videoAttachments;
    }

    public get fileAttachments(): Attachment[]
    {
        return this.attachments.filter((attachment) => {
            return attachment.isFile;
        });
    }

    public get iriRequestObject(): any {
        const object = Object.assign({}, this) as any;
        object['attachments'] = Attachment.toIRIArray(this.attachments);
        object['portfolio'] = '/api/portfolios/' + this.portfolio?.id;
        return object;
    }

    public get iriPath(): string {
        return '/api/posts/' + this.id;
    }

}
