export class HydraCollection<Type>
{
    members: Type[] = [];
    totalItems: number = 0;

    currentPage: string = '';
    nextPage: string | null = null;

    public get hasNext(): boolean {
        return this.nextPage != null;
    }

    public static async deserialize<Type>(data: any, memberDecoder: (data: any) => Promise<Type | null>): Promise<HydraCollection<Type>> {
        const coll: HydraCollection<Type> = new HydraCollection<Type>();

        coll.totalItems = data['hydra:totalItems'];

        if (data['hydra:view']) {
            coll.currentPage = data['hydra:view']['@id'];

            if (data['hydra:view']['hydra:next']) {
                coll.nextPage = data['hydra:view']['hydra:next'];
            }
        }

        for (const memberData of data['hydra:member']) {
            const member: Type | null = await memberDecoder(memberData);

            if (member != null) {
                coll.members.push(member);
            }
        }

        return coll;
    }
}