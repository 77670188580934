import { Comment } from '@/models/Comment';
import { User } from '@/models/User';
import HttpClient from '@/util/httpClient';
import { UserService } from "@/service/UserService";
import {Post} from "@/models/Post";
import moment from "moment";

export class CommentService {
    protected client: HttpClient;
    protected userService: UserService;

    public constructor (opts: any) {
        this.client = opts.httpClient;
        this.userService = opts.userService;
    }

    public async loadComments (postId: number): Promise<Comment[]> {
        const response: any = await this.client.fetch(`posts/${postId}/comments`, null);
        const comments: Comment[] = [];

        if (response.data && response.data['hydra:member']) {
            for (const commentData of response.data['hydra:member']) {
                const comment: Comment = new Comment(
                    commentData.id,
                    commentData.message,
                    '/api/posts/' + postId,
                    UserService.parseUser(commentData.author),
                    commentData.createdAt,
                    commentData.updatedAt,
                );
                comments.push(comment);
            }
        }

        return comments;
    }

    public async postComment(post: Post, user: User, newCommentText: string): Promise<Comment | null> {
        // Use IRI here.
        const postComment = new Comment(null, newCommentText, post.iriPath, user, moment().toDate(), moment().toDate());
        const response = await this.client.post('post_comments', postComment.iriRequestObject, null);
        if (response.data) {
            const data = response.data;
            return new Comment(
                data.id,
                data.message,
                post.iriPath,
                UserService.parseUser(data.author),
                data.createdAt,
                data.updatedAt,
            );
        }
        return null;
    }

    public async deleteComment(comment: Comment): Promise<boolean> {
        const response = await this.client.delete('post_comments/' + comment.id, null);
        return response.status === 204;

    }

    public async updateComment(comment: Comment) {
        const response = await this.client.put('post_comments/' + comment.id, comment.iriRequestObject, null);
        if (response.data) {
            const data = response.data;
            return new Comment(
                data.id,
                data.message,
                data.post,
                UserService.parseUser(data.author),
                data.createdAt,
                data.updatedAt,
            );
        }
        return null;
    }
}
