import { BaseEntity } from "@/models/BaseEntity";
import moment from 'moment';

export class User extends BaseEntity {
    email: string;
    name: string;
    birthdate: Date | null;
    socialAccount: boolean;

    constructor (id: number, idPath: string, email: string, name: string, birthdate: Date | null, socialAccount: boolean = false) {
        super(id, idPath);

        this.email = email;
        this.name = name;
        this.birthdate = birthdate;
        this.socialAccount = socialAccount;
    }

    public get ageInYears(): number {
        const birthdate: moment.Moment = moment(this.birthdate);
        return moment().diff(birthdate, 'years');
    }

    public static createFromData(data: any): User {
        let birthdate: Date | null = null;

        if (data['birthdate'] !== undefined && data['birthdate'] !== null) {
            const momentBirthdate: moment.Moment = moment(data['birthdate']);

            if (momentBirthdate.isValid()) {
                birthdate = momentBirthdate.toDate();
            }
        }

        return new User(data['id'], data['@id'], data['email'], data['name'], birthdate, data['socialAccount']);
    }

    public get isComplete(): boolean {
        return this.birthdate !== null && this.birthdate !== undefined;
    }

    public get firstName(): string | null {
        const parts: string[] = this.name.split(' ');

        if (parts.length > 0) {
            return parts[0];
        }

        return null;
    }

    public get lastName(): string | null {
        const parts: string[] = this.name.split(' ');

        if (parts.length > 1) {
            return parts.slice(1).join(' ');
        }

        return null;
    }
}
