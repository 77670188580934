import {AlertDialogDataInterface} from "@/models/interfaces/AlertDialogDataInterface";

export default class AlertDialogPopupModel {
    title!: string | null;
    message!: string | null;
    actions!: Array<{ name: string, action: (data: AlertDialogDataInterface) => void }> | null;

    constructor(title: string | null, message: string | null, actions: Array<{ name: string, action: (data: AlertDialogDataInterface) => void}> | null) {
        this.title = title;
        this.message = message;
        this.actions = actions;
    }
}
