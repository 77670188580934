// When throwing this exception make sure messages don't contain sensitive information.
import {AxiosError} from 'axios';

export class ApplicationException extends Error {
    constructor(message: string, private _original?: AxiosError) {
        super(message);
    }

    get original(): AxiosError | undefined {
        return this._original;
    }
}
