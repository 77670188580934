import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {ApplicationException} from "@/models/exceptions/ApplicationException";

export default class HttpClient {
    private readonly client: AxiosInstance;
    protected requestMap: any = [];

    public readonly baseURL: string | undefined;
    private readonly apiPath: string | undefined;

    public constructor() {
        this.baseURL = process.env.VUE_APP_API_BASE_URL;
        this.apiPath = process.env.VUE_APP_API_PATH;

        const config: AxiosRequestConfig = {
            withCredentials: true,
            baseURL: this.baseURL,
            headers: {
                'Accept': 'application/ld+json',
                /**
                 * Placeholder Authorization handling until frontend authentication is
                 * in place
                 */
                // 'Authorization': process.env.VUE_APP_API_AUTHORIZATION?.toString() ?? '',
            },
        };

        this.client =  axios.create(config);
        this.client.defaults.timeout = 10000;
        this.client.interceptors.request.use(config => {
            if (config.baseURL === process.env.VUE_APP_API) {
                const token = 'token'; // get token here
                if (token) {
                    // config.headers.Authorization = `Bearer ${token}`;
                }
            }

            return config;
        }, error => Promise.reject(error));
    }

    public async fetch(path: string, params: any, baseURLOnly = false) {
        return this.wrapWithExceptionPromise(async () => {
            const fetchPath: string = baseURLOnly ? path : `${this.apiPath}/${path}`;
            return await this.client.get(fetchPath, params);
        });
    }

    public async safeFetch (path: string, params: any, baseURLOnly = false): Promise<AxiosResponse | null> {
        let response: AxiosResponse | null = null;

        try {
            response = await this.fetch(path, params, baseURLOnly);
        } catch {
            response = null;
        }

        return response;
    }

    public async post(path: string, data: any, params: any, baseURLOnly = false) {
        return this.wrapWithExceptionPromise(async () => {
            const postPath: string = baseURLOnly ? path : `${this.apiPath}/${path}`;
            return await this.client.post(postPath, data, params);
        });
    }

    public async put(path: string, data: any, params: any, baseURLOnly = false) {
        return this.wrapWithExceptionPromise(async () => {
            const postPath: string = baseURLOnly ? path : `${this.apiPath}/${path}`;
            return await this.client.put(postPath, data, params);
        });
    }

    public async delete(path: string, params?: any, baseURLOnly = false): Promise<AxiosResponse> {
        return this.wrapWithExceptionPromise(async () => {
            const postPath: string = baseURLOnly ? path : `${this.apiPath}/${path}`;
            const response: AxiosResponse = await this.client.delete(postPath, params);
            return response;
        });
    }

    private async wrapWithExceptionPromise(f: () => Promise<AxiosResponse>): Promise<AxiosResponse> {
        try {
            return await f();
        } catch (e) {
            if (e instanceof AxiosError && e.response) {
                const status = e.response.status;
                if (status === 401) {
                    throw new ApplicationException('error.unauthorized', e);
                } else if (status === 404) {
                    throw new ApplicationException('error.page_not_found', e);
                } else if (status === 500) {
                    throw new ApplicationException('error.api_response_failed', e);
                } else if (status === 503) {
                    throw new ApplicationException('error.api_unreachable', e);
                } else if (status === 0) {
                    throw new ApplicationException('error.api_no_connection', e);
                } else if (e.response.data && e.response.data.detail) {
                    throw new ApplicationException(e.response.data.detail);
                } else if (e.response.data && e.response.data?.message) {
                    throw new ApplicationException(e.response.data.message);
                } else {
                    throw e;
                }
            } else {
                throw e;
            }
        }
    }
}
