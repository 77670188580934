import moment from 'moment';
import container from '@/container';
import HttpClient from "@/util/httpClient";
import { useRouter } from 'vue-router';

export class SignInWithAppleService {
    private readonly authorizationURL: string = 'https://appleid.apple.com/auth/authorize';
    private readonly clientId: string;
    private readonly popupTimeout: number;
    private readonly responseMode: string;

    public constructor() {
        this.clientId = process.env.VUE_APP_SIGN_IN_WITH_APPLE_CLIENT_ID as string;
        this.popupTimeout = parseInt(process.env.VUE_APP_SIGN_IN_WITH_APPLE_CLIENT_TIMEOUT_TIME || '120000');
        this.responseMode = 'form_post';
    }

    private window: Window | null = null;
    private checkTimer: NodeJS.Timer | null = null;
    private startTime: Date | null = null;

    private resolved: ((success: boolean) => void) | null = null;
    private rejected: ((error: Error | null) => void) | null = null;

    public startAuthentication(state: string, redirectUri: string, nonce: string = ''): Promise<boolean>
    {
        if (window.ZKInitiateSignInWithApple !== undefined) {
            // Native sign in is supported! Let's follow that path
            window.ZKInitiateSignInWithApple(state, redirectUri);
        } else {
            // Default web sign in
            this.showSignIn(state, redirectUri);
        }

        return new Promise<boolean>((resolve, reject) => {
            this.resolved = resolve;
            this.rejected = reject;
        });
    }

    private showSignIn(state: string, redirectUri: string): void
    {
        const url: string = this.getAuthorizeUrl(state, redirectUri);
        const title: string = 'Apple Sign-In';
        const options: string = this.getWindowOptions();

        this.startTime = moment().toDate();
        this.window = window.open(url, title, options);
        this.checkTimer = setInterval(this.checkWindowClosed.bind(this), 500);

        window.addEventListener('message', this.signInResponseListener.bind(this), false);
    }

    private signInResponseListener(event: MessageEvent): void {
        const baseURL: string = process.env.VUE_APP_API_BASE_URL ?? '';

        if (event.origin === baseURL) {
            switch (event.data) {
            case 'auth_success':
                this.handleSigninResponse(true, null);
                break;
            case 'auth_failed':
                this.handleSigninResponse(false, new Error('Authentication failed'));
                break;
            default:
                this.handleSigninResponse(false, new Error('Unknown error occurred during authentication'));
            }

            this.cleanupWindow();
        }
    }

    private handleSigninResponse(success: boolean, error: Error | null): void {
        if (error === null) {
            if (this.resolved !== null) {
                this.resolved(success);
            }
        } else {
            if (this.rejected !== null) {
                this.rejected(error);
            }
        }
    }

    public nativeCallback(payload: string, error: string | null): void
    {
        if (error === null) {
            const url: string = 'user/sign-in-with-apple';
            const client: HttpClient = container.resolve('httpClient');

            client.post(url, payload, null).then((result) => {
                if (this.resolved !== null) {
                    this.resolved(true);
                }
            }, (error) => {
                if (this.rejected !== null) {
                    this.rejected(error);
                }
            });
        } else {
            if (this.rejected !== null) {
                this.rejected(new Error(error));
            }
        }
    }

    private cleanupWindow(): void {
        if (this.window !== null) {
            if (!this.window.closed) {
                this.window.close();
            }

            this.window.removeEventListener('message', this.signInResponseListener.bind(this), false);
            this.window = null;

            if (this.checkTimer !== null) {
                clearInterval(this.checkTimer);
                this.checkTimer = null;
            }
        }
    }

    private checkWindowClosed(): void {
        if (this.window && this.window.closed) {
            this.handleSigninResponse(false, new Error('Window closed before authentication completed'));
            this.cleanupWindow();
        } else {
            const currentTime: Date = moment().toDate();

            if (this.startTime && (this.startTime?.getTime() + this.popupTimeout < currentTime.getTime())) {
                this.cleanupWindow();
            }
        }
    }

    private getAuthorizeUrl(state: string, redirectUri: string, scope: string = 'name+email', responseType: string = 'code+id_token', nonce: string = ''): string
    {
        return `${this.authorizationURL}?response_type=${responseType}&` +
        `client_id=${this.clientId}&scope=${scope}&response_mode=${this.responseMode}&` +
        `state=${state}&redirect_uri=${redirectUri}&nonce=${nonce}`;
    }

    private getWindowOptions(): string {
        const width: number = 450;
        const height: number = 600;
        const left: number = 0;
        const top: number = 0;

        return `menubar=no,location=no,status=no,width=${width},height=${height},top=${top},left=${left}`;
    }
}

declare global {
    interface Window {
        ZKInitiateSignInWithApple(state: string, redirectUri: string): void;
        ZKNativeAuthenticationCallback(response: string, error: string | null): void;
        ZKSignInWithAppleRevoked(): void;

        // implemented elsewhere (in App.vue)
        logout(): void;
    }
}

window.ZKNativeAuthenticationCallback = function (response: string, authError: string | null): void
{
    const signInService: SignInWithAppleService = container.resolve('appleService');
    signInService.nativeCallback(response, authError);
};

const router = useRouter();

window.ZKSignInWithAppleRevoked = function (): void
{
    if (window.logout !== undefined) {
        window.logout();
    }
};
