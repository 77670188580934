import { BaseEntity } from "@/models/BaseEntity";

export class Attachment extends BaseEntity {
    title: string;
    path: string;
    mimeType: string;

    constructor (id: number, idPath: string, title: string, path: string, mimeType: string) {
        super(id, idPath);

        this.title = title;
        this.path = path;
        this.mimeType = mimeType;
    }

    public get isExternal(): boolean {
        return this.path.startsWith('http');
    }

    public get isImage(): boolean {
        return this.mimeType.startsWith('image');
    }

    public get isVideo(): boolean {
        return this.mimeType.startsWith('video');
    }

    public get isFile(): boolean {
        return this.fileIcon !== null;
    }

    public get fileIcon(): string | null {
        if (this.isVideo || this.isImage) {
            return null;
        }
        switch (this.mimeType) {
        case 'application/pdf':
            return require('@/assets/icon-pdf.svg');
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return require('@/assets/icon-document.svg');
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.ms-powerpoint':
            return require('@/assets/icon-powerpoint.svg');
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return require('@/assets/icon-excel.svg');
        }
        return null;
    }

    public static toIRIArray(attachments: Attachment[]): string[] {
        return attachments.map((attachment: Attachment) => attachment.idPath);
    }
}
