import { BaseEntity } from "@/models/BaseEntity";
import { Portfolio } from "@/models/Portfolio";

export class Order extends BaseEntity {
    membershipAmount: number;
    status: string;
    orderDate: Date | null;
    portfolios: Portfolio[];

    constructor (id: number, idPath: string, membershipAmount: number, status: string, orderDate: Date | null, portfolios: Portfolio[]) {
        super(id, idPath);

        this.membershipAmount = membershipAmount;
        this.status = status;
        this.orderDate = orderDate;
        this.portfolios = portfolios;
    }

    public static createFromData(data: any): Order {
        let portfolios: Portfolio[] = [];

        if (data['portfolioOrders'].length >  0) {
            portfolios = data['portfolioOrders'].map(Portfolio.fromIRIRef);
        }

        return new Order(data['id'], data['@id'], data['membershipAmount'], data['status'], data['orderDate'], portfolios);
    }

    public get assignedPortfolioCount(): number {
        return this.portfolios.length;
    }

    public get unassignedPortfolioCount(): number {
        return this.membershipAmount - this.portfolios.length;
    }
}
