import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n from './i18n';
import App from './App.vue';
import router from './router';

import 'bootstrap';
import {useGlobalStore} from "@/stores/global";
import container from "@/container";
import {UserService} from "@/service/UserService";
import axios from 'axios';
import {User} from '@/models/User';
import {PortfolioService} from "@/service/PortfolioService";
import {useDialog} from "@/composables/dialogMessage";


const app = createApp(App);
app.use(createPinia());
app.use(i18n);

const dialog = useDialog();
const store = useGlobalStore();
const userService: UserService = container.resolve('userService');
const portfolioService: PortfolioService = container.resolve('portfolioService');

// Initialize callback 'registry' to track android callbacks.
window.AndroidCallbackManager = {};

router.beforeEach(async (to) => {
    const isAuthenticated = store.authenticated;
    const isPublicRoute = (to.meta?.isPublic && to.meta.isPublic) || false;
    if (process.env.NODE_ENV === 'development') {
        console.log("isAuthenticated " + isAuthenticated + " isPublicRoute: " + isPublicRoute + " route: " + (to.name as string));
    }

    if (to.path.includes('portfolio/invite')) {
        const hash = to.path.substring(to.path.lastIndexOf('/') + 1);
        localStorage.setItem('registrationReference', hash);
        return { path: store.authenticated ? '/portfolio' : '/login' };
    }

    if (isAuthenticated && to.name === 'app_logout') {
        await userService.logout();
        store.authenticated = false;
        store.currentUser = null;
        return { name: 'app_login' };
    }

    const registrationReference = localStorage.getItem("registrationReference");
    if (isAuthenticated && registrationReference) {
        try {
            await portfolioService.finalizeInvitation(registrationReference);
        } catch (e) {
            dialog.dialogWhenApplicationError(e);
        }
    }

    if (!isAuthenticated && !isPublicRoute && to.name !== 'app_login') {
        return { name: 'app_login' };
    }
});

try {
    const storedUser = JSON.parse(localStorage.getItem('currentUser') || 'null');
    if (storedUser) {
        store.authenticated = true;
        store.currentUser = User.createFromData(storedUser);
    }
} catch (e) {
    // ignore
}

/* intercept responses to logout user */
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            store.authenticated = false;
            store.currentUser = null;
            router.push('login');
        }
    });

app.use(router);

app.mount('#app');
