import { asClass, createContainer, InjectionMode } from "awilix";
import HttpClient from "@/util/httpClient";
import { PostService} from "@/service/PostService";
import { CommentService} from "@/service/CommentService";
import { AttachmentService } from "@/service/AttachmentService";
import { UserService } from "@/service/UserService";
import {FacebookService} from "@/service/FacebookService";
import {GoogleService} from "@/service/GoogleService";
import { PortfolioService } from "@/service/PortfolioService";
import { ContactService } from "@/service/ContactService";
import { OrderService } from "@/service/OrderService";
import { SignInWithAppleService } from "@/service/SignInWithAppleService";

const container = createContainer({
    injectionMode: InjectionMode.PROXY,
});

container.register({
    httpClient: asClass(HttpClient).singleton(),
    postService: asClass(PostService).singleton(),
    commentService: asClass(CommentService).singleton(),
    attachmentService: asClass(AttachmentService).singleton(),
    userService: asClass(UserService).singleton(),
    portfolioService: asClass(PortfolioService).singleton(),
    contactService: asClass(ContactService).singleton(),
    orderService: asClass(OrderService).singleton(),
    facebookService: asClass(FacebookService).singleton(),
    googleService: asClass(GoogleService).singleton(),
    appleService: asClass(SignInWithAppleService).singleton(),
});

export default container;
