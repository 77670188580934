import { createI18n, IntlDateTimeFormats, LocaleMessages, VueMessageType } from 'vue-i18n';

function loadLocaleMessages (): LocaleMessages<VueMessageType> {
    const locales = require.context('./locales', true, /[A-Za-z0-9-\-,\s]+\.json$/i);
    const messages: LocaleMessages<VueMessageType> = {};

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9--]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}

const datetimeFormats: IntlDateTimeFormats = {
    'nl-NL': {
        short: {
            year: 'numeric', month: 'long', day: 'numeric',
            hour: 'numeric', minute: 'numeric',
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric',
        },
    },
};


// const i18n = createI18n<[LocaleMessages], 'nl-NL'>({
const i18n = createI18n({
    locale: 'nl-NL',
    messages: loadLocaleMessages(),
    datetimeFormats: datetimeFormats,
});

export default i18n;
