import { defineStore } from 'pinia';
import { Contact } from '@/models/Contact';
import { NewUser } from '@/models/NewUser';
import {User} from "@/models/User";
import {Portfolio} from '@/models/Portfolio';
import AlertDialogPopupModel from "@/models/AlertDialogPopupModel";
import {OrderInfo} from '@/models/OrderInfo';

export type RootState = {
    authenticated: boolean,
    contact: Contact | null,
    newUser: NewUser | null,
    currentPortfolio: Portfolio | null,
    currentUser: User | null,
    tintedHeader: boolean,
    alertMessage: AlertDialogPopupModel | null,
    order: OrderInfo | null,
};

export const useGlobalStore = defineStore('global', {
    state: () => ({
        authenticated: false,
        contact: null,
        newUser: null,
        currentPortfolio: null,
        currentUser: null,
        tintedHeader: false,
        alertMessage: null,
        order: null,
    } as RootState),

    getters: {
        chooseUser: (state) => state.contact == null,
    },
});
