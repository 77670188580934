import {Post} from '@/models/Post';
import {Attachment} from '@/models/Attachment';
import {FetchPostOptions} from '@/models/FetchPostOptions';
import HttpClient from '@/util/httpClient';
import {AttachmentService} from "@/service/AttachmentService";
import {HydraCollection} from "@/models/HydraCollection";
import {AxiosResponse} from "axios";
import {Portfolio} from '@/models/Portfolio';
import {useDialog} from '@/composables/dialogMessage';
import {reactive} from 'vue';

export class PostService {
    protected client: HttpClient;
    protected attachmentService: AttachmentService;

    public constructor (opts: any) {
        this.client = opts.httpClient;
        this.attachmentService = opts.attachmentService;
    }

    private async parsePostData(postData: any): Promise<Post> {
        let attachments: Attachment[] = reactive([]);

        if (postData.attachments.length > 0) {
            attachments = reactive([]);
            const dialog = useDialog();
            this.attachmentService.loadAttachments(postData.id).then((result) => {
                attachments.push(...result);
            }, (e) => {
                dialog.dialogWhenApplicationError(e);
            });
        }

        return new Post(
            postData.id,
            attachments,
            postData.title,
            postData.description,
            postData.eventDate,
            postData.postDate,
            postData.type,
            postData.comments,
            postData.location,
            null,
            postData.author,
        );
    }

    public async loadAdditionalPosts(collection: HydraCollection<Post>): Promise<HydraCollection<Post>>
    {
        const response = await this.client.fetch(collection.nextPage!, null, true);
        const nextCollection: HydraCollection<Post> =
            await HydraCollection.deserialize<Post>(response.data, (async (postData: any) => {
                return this.parsePostData(postData);
            }));

        nextCollection.members = [...collection.members, ...nextCollection.members];

        return nextCollection;
    }

    public async loadPosts (portfolioId: number, options: FetchPostOptions | null): Promise<HydraCollection<Post>> {
        let fetchLocation: string = `portfolios/${portfolioId}/posts`;

        if (options != null) {
            fetchLocation += options.urlParameters;
        }

        const response = await this.client.fetch(fetchLocation, null);
        const collection: HydraCollection<Post> =
            await HydraCollection.deserialize<Post>(response.data, (async (postData: any) => {
                return this.parsePostData(postData);
            }));

        return collection;
    }

    public async loadPost(id: number | string): Promise<Post | null>{
        const response = await this.client.fetch(`posts/${id}`, null);
        if (response.data) {
            const postData = response.data;
            let attachments: Attachment[] = [];
            if (postData.attachments.length > 0) {
                attachments = await this.attachmentService.loadAttachments(postData.id);
            }
            const portfolio = typeof postData.portfolio === 'string' ? Portfolio.fromIRIRef(postData.portfolio)
                : Portfolio.fromIRIRef(postData.portfolio['@id']);
            return new Post(
                postData.id,
                attachments,
                postData.title,
                postData.description,
                postData.eventDate,
                postData.postDate,
                postData.type,
                postData.comments,
                postData.location,
                portfolio,
                postData.author);
        }
        return null;
    }

    public async createPost(post: Post): Promise<Post> {
        const response = await this.client.post("posts", post.iriRequestObject, null);
        if (response.data) {
            return this.parsePostData(response.data);
        }
        return Post.empty();
    }

    public async updatePost(post: Post): Promise<Post> {
        const response = await this.client.put("posts/" + post.id, post.iriRequestObject, null);
        if (response.data) {
            return this.parsePostData(response.data);
        }
        return Post.empty();
    }

    public async deletePost(post: Post): Promise<AxiosResponse> {
        return await this.client.delete(`posts/${post.id}`, {});
    }
}
