import { createRouter, createWebHashHistory } from 'vue-router';

const router = createRouter({
    history: createWebHashHistory(),
    routes: [{
        name: 'root',
        path: '/',
        component: () => import('../components/AppComponent.vue'),

        children: [
            {
                path: '/login',
                name: 'app_login',
                component: () => import('../components/LoginComponent.vue'),
                meta: {
                    isPublic: true,
                },
            },
            {
                path: '/logout',
                name: 'app_logout',
                component: () => import('../components/LoginComponent.vue'),
                meta: {
                    isPublic: true,
                },
            },
            {
                path: '/forgot-password',
                name: 'forgot_password',
                component: () => import('../components/ForgotPasswordComponent.vue'),
                meta: {
                    isPublic: true,
                },
            },
            {
                path: '/registration-intro',
                name: 'registration_intro',
                component: () => import('../components/AccountRegistration/RegistrationIntro.vue'),
                meta: {
                    isPublic: true,
                },
            },
            {
                path: '/registration-email',
                name: 'registration_email',
                component: () => import('../components/AccountRegistration/RegistrationEmail.vue'),
                meta: {
                    isPublic: true,
                },
            },
            {
                path: '/registration-password',
                name: 'registration_password',
                component: () => import('../components/AccountRegistration/RegistrationPassword.vue'),
                meta: {
                    isPublic: true,
                },
            },
            {
                path: '/registration-info',
                name: 'registration_info',
                component: () => import('../components/AccountRegistration/RegistrationPersonalInfo.vue'),
                meta: {
                    isPublic: true,
                },
            },
            {
                path: '/registration-complete',
                name: 'registration_complete',
                component: () => import('../components/AccountRegistration/RegistrationComplete.vue'),
                meta: {
                    isPublic: true,
                },
            },
            {
                path: '', component: () => import('../components/DashboardComponent.vue'),
                name: 'dashboard',
                children: [
                    {
                        path: 'portfolio',
                        name: 'portfolio',
                        component: () => import('../components/Portfolio/PortfolioComponent.vue'),

                        children: [
                            {
                                path: '',
                                name: 'portfolios',
                                component: () => import('../components/Portfolio/Portfolios.vue'),
                            },
                            {
                                path: 'order',
                                name: 'portfolio_order_setup',
                                component: () => import('../components/Portfolio/Order/Setup.vue'),
                            },
                            {
                                path: 'order/other',
                                name: 'portfolio_order_other',
                                component: () => import('../components/Portfolio/Order/Amount.vue'),
                            },
                            {
                                path: 'order/self',
                                name: 'portfolio_order_self',
                                component: () => import('../components/Portfolio/Order/SingleDiscount.vue'),
                            },
                            {
                                path: 'order/discount',
                                name: 'portfolio_order_discount',
                                component: () => import('../components/Portfolio/Order/SingleDiscount.vue'),
                            },
                            {
                                path: 'order/overview',
                                name: 'portfolio_order_overview',
                                component: () => import('../components/Portfolio/Order/Overview.vue'),
                            },
                            {
                                path: ':portfolio',
                                name: 'portfolio_timeline',
                                component: () => import('../components/Portfolio/PortfolioTimeline.vue'),
                            },
                        ],
                    },
                    {
                        path: '/portfolio/post/create',
                        name: 'portfolio_post_create',
                        component: () => import('../components/Portfolio/PortfolioPostForm.vue'),
                    },
                    {
                        path: '/portfolio/post/update/:id',
                        name: 'portfolio_post_update',
                        component: () => import('../components/Portfolio/PortfolioPostForm.vue'),
                    },
                    {
                        path: 'contacts',
                        name: 'contacts',
                        component: () => import('../components/Contacts/ContactsComponent.vue'),
                    },
                ],
            },
        ],
    }],
});

export default router;
