export class DummyContact {
    name: string;
    group?: string | null;
    age?: Date | null;
    profilePicture: string | null;
    portfolioId: string | number | null;

    constructor (name: string, group: string | null, age: Date | null, profilePicture: string | null, portfolioId: number) {
        this.name = name;
        this.group = group;
        this.age = age;
        this.profilePicture = profilePicture;
        this.portfolioId = portfolioId;
    }

    public get ageInYears (): number {
        return 24;
    }

    /*
    public get profilePicture (): String {
        return 'logo.png'
    }
    */

    public get firstName (): string {
        return this.name.split(' ')[0];
    }
}
