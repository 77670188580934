import container from "@/container";
import SignInResponse from "@/models/SignInResponse";

export class FacebookService {

    public readonly appId: string;

    public constructor() {
        this.appId = process.env.VUE_APP_FACEBOOK_APP_ID as string;
    }

    private resolved: ((response: SignInResponse) => void) | null = null;
    private rejected: ((error: Error | null) => void) | null = null;

    public facebookLogin(options = {scope: 'email'}): Promise<SignInResponse> {
        return new Promise<SignInResponse>((resolve, reject) => {
            if (window.Android && typeof window.Android.startFacebookLogin === 'function') {
                // Native sign in is supported! Let's follow that path
                window.Android.startFacebookLogin();
            } else {
                // Default web sign in
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                window.FB?.login((response: any) => {
                    if (response.status === 'connected') {
                        resolve(new SignInResponse(true, response.authResponse.accessToken));
                    } else {
                        reject('Authentication unsuccessful');
                    }
                }, options);
            }
            this.resolved = resolve;
            this.rejected = reject;
        });
    }

    public initFacebook() {
        window.FB.init({
            appId: this.appId, //You will need to change this
            cookie: true, // This is important, it's not enabled by default
            version: "v13.0",
        });
    }

    async loadFacebookSDK(d: Document) {
        const fjs = d.getElementsByTagName("script")[0];
        if (d.getElementById("facebook-jssdk")) {
            return;
        }
        const js = d.createElement("script") as HTMLScriptElement;
        js.id = "facebook-jssdk";
        js.src = "https://connect.facebook.net/en_US/all.js";
        fjs.parentNode?.insertBefore(js, fjs);
    }

    load(params = { locale: 'en_US' }) {
        return new Promise((resolve, reject) => {
            if (window.FB) {
                return resolve(window.FB);
            }

            const src = `//connect.facebook.net/${params.locale}/sdk.js`;
            const script = document.createElement('script');
            script.id = 'facebook-jssdk';
            script.src = src;
            script.async = true;
            script.addEventListener('load', () => resolve(window.FB), false);
            script.addEventListener(
                'error',
                () => reject(`Error loading Facebook JS SDK from ${src}`),
                false,
            );

            const sibling = document.getElementsByTagName('script')[0];
            sibling.parentNode?.insertBefore(script, sibling);
        });
    }

    public androidNativeCallback(payload: string | null): void
    {
        if (payload === null && this.rejected != null) {
            this.rejected(Error('Failed to retrieve access token'));
        } else if (payload && this.resolved != null) {
            this.resolved(new SignInResponse(true, payload));
        }
    }
}

declare global {
    interface Window {
        onFacebookLoginCallback(token: string | null): void;
        Android: Android;
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        FB: any;
    }
    interface Android {
        startFacebookLogin(): void;
    }
}

window.onFacebookLoginCallback = function (token: string | null): void {
    const signInService: FacebookService = container.resolve('facebookService');
    signInService.androidNativeCallback(token);
};
