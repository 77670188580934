import { Contact } from '@/models/Contact';
import { InviteContact } from '@/models/InviteContact';
import { InviteResponse } from '@/models/enums/InviteResponse';
import { InvitationStatus } from '@/enums/InvitationStatus';
import HttpClient from '@/util/httpClient';
import { DummyContact } from '@/models/DummyContact';
import { ContactFactory } from '@/factories/ContactFactory';
import { HydraCollection } from "@/models/HydraCollection";
import { UserService } from "@/service/UserService";

export class ContactService {
    protected client: HttpClient;
    protected userService: UserService;

    public constructor (opts: any) {
        this.client = opts.httpClient;
        this.userService = opts.userService;
    }

    public loadDummyContacts (): Promise<DummyContact[]> {
        const promise: Promise<DummyContact[]> = new Promise((resolve, reject) => {
            resolve(this.dummyContacts());
        });

        return promise;
    }

    private dummyContacts (): DummyContact[] {
        const contacts: DummyContact[] = [
        ];

        let contact: DummyContact = ContactFactory.newContact('Daisy Busser', 'Groep 10', new Date(), 'placeholder/sst_duck.webp', 1);
        contacts.push(contact);

        contact = ContactFactory.newContact('Koos Mavrakis', 'Groep 2', new Date(), 'placeholder/sst_koos_mavrakis.webp', 2);
        contacts.push(contact);
        return contacts;
    }

    private static parseInvitationStatus(invitationStatus: string): InvitationStatus {
        if (invitationStatus === 'PENDING') {
            return InvitationStatus.Pending;
        } else if (invitationStatus === 'ACCEPTED') {
            return InvitationStatus.Accepted;
        }

        return InvitationStatus.Rejected;
    }

    private async parseContactData(data: any): Promise<Contact | null> {
        return new Contact(
            data.id,
            data.idPath,
            null,
            data.user ? UserService.parseUser(data.user) : null,
            data.email,
            ContactService.parseInvitationStatus(data.status),
            data.updatedAt,
            data.createdAt,
        );
    }

    public async loadContactsForPortfolio(portfolioId: number): Promise<HydraCollection<Contact>> {
        const fetchLocation: string = `portfolios/${portfolioId}/contacts`;
        const response = await this.client.fetch(fetchLocation, null);

        const collection: HydraCollection<Contact> =
            await HydraCollection.deserialize<Contact>(response.data, (async (data: any) => {
                return await this.parseContactData(data);
            }));

        return collection;
    }

    public async deleteContact(contact: Contact): Promise<boolean> {
        const deleteLocation: string = `portfolio_contacts/${contact.id}`;
        const response:any = await this.client.delete(deleteLocation);
        return response.status === 204;
    }

    public async inviteContact(invitation: InviteContact): Promise<InviteResponse> {
        const inviteLocation: string = 'portfolio_contacts/invite';
        const response: any = await this.client.post(inviteLocation, invitation, null);

        if (response != null && response.data != null) {
            if (response.data['success'] === true) {
                return InviteResponse.SUCCESS;
            } else {
                if (response.data['message'] === 'invitation_exists') {
                    return InviteResponse.ERROR_INVITATION_EXISTS;
                }
            }
        }

        return InviteResponse.ERROR_OTHER;
    }
}
