import {Attachment} from '@/models/Attachment';
import HttpClient from "@/util/httpClient";
import {Post} from "@/models/Post";

export class AttachmentService {
    protected client: HttpClient;

    public constructor (opts: any) {
        this.client = opts.httpClient;
    }

    public fetchAttachmentDataLocation(attachmentLink: string): string | null
    {
        return this.client.baseURL + attachmentLink + "/data";
    }

    public fetchAttachmentDataLocations(attachmentLinks: string[]): string[]
    {
        const links: string[] = [];

        attachmentLinks.forEach((link: string) => {
            const attachmentDataLink = this.fetchAttachmentDataLocation(link);

            if (attachmentDataLink) {
                links.push(attachmentDataLink);
            }
        });

        return links;
    }

    public fetchDataLocation(attachment: Attachment): string | null
    {
        if (!attachment.isExternal) {
            const link = `/api/attachment/${attachment.id}`;
            return this.fetchAttachmentDataLocation(link);
        }
        return attachment.path;
    }

    public async loadAttachments(postId: number): Promise<Attachment[]> {
        const response: any = await this.client.fetch(`posts/${postId}/attachments`, null);
        const attachments: Attachment[] = [];

        if (response.data && response.data['hydra:member']) {
            for (const attachmentData of response.data['hydra:member']) {
                const attachment: Attachment = new Attachment(
                    attachmentData.id,
                    attachmentData['@id'],
                    attachmentData.title,
                    attachmentData.path,
                    attachmentData.mimeType);

                attachments.push(attachment);
            }
        }

        return attachments;
    }

    public async uploadAttachment(post: Post, file: File): Promise<Attachment | null> {
        const formData = new FormData();
        formData.append('attachment', file);
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const response = await this.client.post('attachment/?postId=' + post.id, formData, { headers });
        if (response.data && response.data['@id']) {
            const attachmentData = response.data;
            return new Attachment(
                attachmentData.id,
                attachmentData['@id'],
                attachmentData.title,
                attachmentData.path,
                attachmentData.mimeType);
        }
        return null;
    }

    public async uploadVideoAttachment(post: Post, attachment: Attachment) {
        const response = await this.client.post('attachment/video?postId=' + post.id, attachment, null);
        if (response.data && response.data['@id']) {
            const attachmentData = response.data;
            return new Attachment(
                attachmentData.id,
                attachmentData['@id'],
                attachmentData.title,
                attachmentData.path,
                attachmentData.mimeType);
        }
        return null;
    }

    public async deleteAttachment(attachment: Attachment) {
        return await this.client.delete('post_attachments/' + attachment.id, null);
    }
}
