import {Order} from '@/models/Order';
import {OrderStatus} from '@/enums/OrderStatus';
import {OrderTransaction} from '@/models/OrderTransaction';
import HttpClient from '@/util/httpClient';
import container from '@/container';
import {safeNumber} from "@/util/safeNumber";
import {callAndroidAsync} from "@/util/asyncAndroidCall";
import amount from "@/components/Portfolio/Order/Amount.vue";
import moment from 'moment';
import {AndroidBillingResponse} from "@/models/AndroidBillingResponse";
import {AxiosError} from "axios";
import {AndroidPurchase} from "@/models/AndroidPurchase";

export class OrderService {
    protected client: HttpClient;

    public constructor (opts: any) {
        this.client = opts.httpClient;
    }

    public async myActiveOrder(): Promise<Order | null> {
        try {
            const response = await this.client.fetch('order/active', null);

            if (response.data) {
                return Order.createFromData(response.data);
            }
        } catch (e) {
            if (e instanceof AxiosError) {
                if (e.status == '404') {
                    console.log(e.response?.statusText);
                    return null;
                }
            }
            throw e;
        }

        return null;
    }

    public async postOrderTransaction(orderTransaction: OrderTransaction): Promise<boolean>
    {
        const response = await this.client.post('order/post',
            orderTransaction, null);

        return response.status == 204;
    }

    public async pricePerMonth(portfolioCount: number): Promise<number> {
        return await this.pricePerYear(portfolioCount) / 12;
    }

    public async pricePerYear(portfolioCount: number): Promise<number> {
        if (typeof window.ZKFetchPortfolioPrice === 'function') {
            return window.ZKFetchPortfolioPrice(portfolioCount);
        }
        if (window.Android && typeof window.Android.getPortfolioPrice === 'function') {
            const price = await callAndroidAsync('getPortfolioPrice', {'amount': portfolioCount});
            const priceNumber = safeNumber(price);
            if (priceNumber) {
                return priceNumber;
            } else {
                throw Error("No price could be returned");
            }
        }

        throw new Error('Unable to fetch portfolio price');
    }

    /*
     * Discount is currently not supported
     */
    public discountPerYear(portfolioCount: number): number {
        return 0;
    }

    public async maxPortfolioOrderCount(): Promise<number> {
        if (typeof window.ZKFetchMaxPortfolioOrderCount === 'function') {
            return window.ZKFetchMaxPortfolioOrderCount();
        }

        if (window.Android && typeof window.Android.getMaxPortfolioOrderCount === 'function') {
            const result = await callAndroidAsync('getMaxPortfolioOrderCount', null);
            return safeNumber(result) || 0;
        }

        throw new Error('Unable to fetch max portfolio order count');
    }

    public async initiateNativePayment(count: number, promptDiscount: boolean, forSelf: boolean): Promise<OrderStatus> {
        if (typeof window.ZKInitiateNativePayment === 'function') {
            const status: string = await window.ZKInitiateNativePayment(count, promptDiscount, forSelf);
            switch (status) {
            case "success":
                return OrderStatus.Success;
            case "pending":
                return OrderStatus.Pending;
            case "cancelled":
                return OrderStatus.Cancelled;
            default:
                return OrderStatus.Unknown;
            }
        } else if (window.Android && typeof window.Android.initiatePlayStoreFlowAsync === "function") {
            try {
                const result = await callAndroidAsync('initiatePlayStoreFlowAsync', {'amount': amount});
                const responseObject = new AndroidBillingResponse(JSON.parse(result));
                if (responseObject.isSuccess && responseObject.data != null) {
                    const json = JSON.parse(responseObject.data);
                    const purchases: AndroidPurchase[] = [];
                    for (let i = 0; i < json.length; i++) {
                        purchases.push(new AndroidPurchase(json[i]));
                    }
                    if (purchases.length > 0) {
                        this.androidPaymentCallback(purchases, forSelf);
                        return OrderStatus.Success;
                    }
                } else if (responseObject.data === "cancelled") {
                    return OrderStatus.Cancelled;
                } else if (responseObject.data === "noBillingResult" || responseObject.data === "billingFlowFailed") {
                    return OrderStatus.Unknown;
                }
                return OrderStatus.Unknown;
            } catch {
                return OrderStatus.Unknown;
            }
        } else {
            throw new Error('No native payment available');
        }
    }

    // Naming to be consistent, is actually already awaited so not actually a callback.
    public androidPaymentCallback(purchases: AndroidPurchase[], forSelf: boolean): void {
        console.log("Calling payment callback on order service");
        const androidTransaction = OrderTransaction.createFromAndroidData(purchases, forSelf);
        this.postOrderTransaction(androidTransaction).then((response: boolean) => {
            console.log(`Transaction: ${response}`);
        }).catch((error) => {
            console.log(`Error: ${error}`);
        });
    }

    public isBillingClientAndroidConnected() {
        if (window.Android && typeof window.Android.isBillingClientConnected === 'function') {
            return window.Android.isBillingClientConnected() === 'true';
        }
        return true;
    }

    public paymentCallback(orderDate: Date, payload: string): void {
        const transaction: OrderTransaction = new OrderTransaction(orderDate, payload);

        this.postOrderTransaction(transaction).then((response: boolean) => {
            console.log('Transaction response: ', response);
        }, (error: Error) => {
            console.error(error);
        });
    }
}

declare global {
    interface Window {
        ZKFetchPortfolioPrice(count: number): Promise<number>;
        ZKFetchMaxPortfolioOrderCount(): Promise<number>;

        ZKInitiateNativePayment(count: number, promptDiscount: boolean, forSelf: boolean): Promise<string>;
        ZKNativePaymentCallback(orderDateString: string, data: any): void;

        Android: Android;
        AndroidCallbackManager: { [key: string]: any };
    }
    interface Android {
        getPortfolioPrice(amount: number): string;
        initiatePlayStoreFlowAsync(amount: number): string;
        runAsync(callKey: string, functionName: string, params: string | null): void;
        runAsyncResult(callKey: string): string;
        getMaxPortfolioOrderCount(): string
        isBillingClientConnected(): string
    }
}

window.ZKNativePaymentCallback = function (orderDateString: string, payload: string): void {
    console.log('Native payment has responded');
    const orderDate: Date = moment(orderDateString).toDate();
    const orderService: OrderService = container.resolve('orderService');
    orderService.paymentCallback(orderDate, payload);
};
