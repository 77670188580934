export class AndroidPurchase {
    orderId!: string;
    packageName!: string;
    productId!: string;
    purchaseTime!: number;
    purchaseState!: number;
    purchaseToken!: string;
    quantity!: number;
    autoRenewing!: boolean;
    acknowledged!: boolean;

    constructor(init?: any) {
        Object.assign(this, init);
    }
}
