
import {defineComponent, onMounted, watch} from 'vue';
import {useGlobalStore} from '@/stores/global';
import container from '@/container';
import {useRouter} from 'vue-router';
import {AxiosError} from 'axios';
import {useDialog} from "@/composables/dialogMessage";
import {useI18n} from "vue-i18n";
import {ApplicationException} from '@/models/exceptions/ApplicationException';

export default defineComponent({
    name: 'App',
    setup() {
        const router = useRouter();
        const store = useGlobalStore();
        const dialog = useDialog();
        const {t} = useI18n();

        watch(() => store.currentUser, () => {
            let storedUser = store.currentUser as unknown;

            if (store.currentUser) {
                storedUser = {
                    id: store.currentUser.id,
                    '@id': store.currentUser.idPath,
                    name: store.currentUser.name,
                    email: store.currentUser.email,
                };
            }
            localStorage.setItem('currentUser', JSON.stringify(storedUser));
        });


        const userService = container.resolve('userService');
        onMounted(async () => {
            window.logout = () => {
                router.push({ name: 'app_logout' });
            };

            try {
                store.currentUser = await userService.currentUser();
            } catch (e) {
                const handleAxiosError = async (e: AxiosError | undefined) => {
                    if (e && e.response && e.response.status === 401) {
                        store.authenticated = false;
                        store.currentUser = null;

                        await router.push({name: 'app_login'});
                        return;
                    }
                };

                if (e instanceof ApplicationException) {
                    await handleAxiosError(e.original);
                } else if (e instanceof AxiosError) {
                    await handleAxiosError(e);
                }
            }
        });
    },
});
