import { Portfolio } from '@/models/Portfolio';
import {Membership} from "@/models/Membership";
import {MembershipInvitation} from "@/models/MembershipInvitation";
import HttpClient from '@/util/httpClient';
import {HydraCollection} from "@/models/HydraCollection";
import {UserService} from "@/service/UserService";
import {PostService} from "@/service/PostService";
import {ApplicationException} from "@/models/exceptions/ApplicationException";

export class PortfolioService {
    protected client: HttpClient;
    protected userService: UserService;
    protected postService: PostService;

    public constructor (opts: any) {
        this.client = opts.httpClient;
        this.userService = opts.userService;
        this.postService = opts.postService;
    }

    private async parsePortfolioData(data: any): Promise<Portfolio | null> {
        return new Portfolio(
            data.id,
            data.idPath,
            UserService.parseUser(data.owner),
            data.startDate,
            [],
            data.updatedAt,
            data.createdAt,
        );
    }

    public async loadPortfolios(): Promise<HydraCollection<Portfolio>> {
        const fetchLocation: string = 'portfolios/';

        const response = await this.client.fetch(fetchLocation, null);
        const collection: HydraCollection<Portfolio> =
            await HydraCollection.deserialize<Portfolio>(response.data, (async (responseData: any) => {
                return this.parsePortfolioData(responseData);
            }));

        return collection;
    }

    public async loadMyPortfolio(): Promise<Portfolio | null> {
        const response = await this.client.fetch('portfolio/me', {});
        if (response.status !== 200) {
            return null;
        }
        return this.parsePortfolioData(response.data);
    }

    public async loadPortfolio(portfolioId: number): Promise<Portfolio | null> {
        const response = await this.client.fetch('portfolios/' + portfolioId, {});
        if (response.status !== 200) {
            return null;
        }
        return this.parsePortfolioData(response.data);
    }

    public async loadMemberships(): Promise<Membership[]> {
        const { data } = await this.client.fetch('portfolio/linkable', null);
        if (data.success) {
            return data.data.map((membership: any) => this.parseMembership(membership));
        } else {
            throw new Error(data.message);
        }
    }

    public async invite(membership: Membership, invitation: MembershipInvitation): Promise<MembershipInvitation> {
        const iriRequestObject = { membership: membership.idPath, ...invitation.iriRequestObject };
        const { data } = await this.client.post(`membership_invitations`, iriRequestObject, null);
        if (data) {
            return this.parseMembershipInvitation(data);
        } else {
            throw new Error(data.message ?? 'Unable to send invite!');
        }
    }

    public async finalizeInvitation(registrationReference: string) {
        const { data } = await this.client.post('portfolio/invite/' + registrationReference + '/accept', {}, null);
        if (data.success || data.clearCache) {
            localStorage.removeItem('registrationReference');
        }
        if (!data.success) {
            throw new ApplicationException(data.message ?? 'Unable to accept invitation');
        }
    }

    private parseMembership(data: any): Membership {
        return new Membership(data.id, data.startDate, data.duration, data.invitation ? this.parseMembershipInvitation(data.invitation) : null);
    }

    private parseMembershipInvitation(data: any): MembershipInvitation {
        return new MembershipInvitation(data.id, data.name, data.email, data.hash);
    }

    public async myPortfolio(): Promise<Portfolio | null> {
        const fetchLocation: string = 'portfolio/me';

        const response = await this.client.safeFetch(fetchLocation, null);

        if (response && response.data) {
            return this.parsePortfolioData(response.data);
        }

        return null;
    }
}
