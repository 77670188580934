import SignInResponse from "@/models/SignInResponse";
import container from "@/container";

export class GoogleService {
    private resolved: ((response: SignInResponse) => void) | null = null;
    private rejected: ((error: Error | null) => void) | null = null;

    login(): Promise<SignInResponse> {
        return new Promise<SignInResponse>((resolve, reject) => {
            if (window.Android && typeof window.Android.startGoogleLogin === 'function') {
                // Native sign in is supported! Let's follow that path
                window.Android.startGoogleLogin();
            } else if (window.ZKInitiateStartGoogleLogin !== undefined) {
                window.ZKInitiateStartGoogleLogin();
            } else {
                // No web login supported
                // this.tokenClient?.requestAccessToken();
                // resolve(new SignInResponse(true, null));
            }
            this.resolved = resolve;
            this.rejected = reject;
        });
    }

    public canLoginWithGoogle(): boolean {
        return (window.Android && typeof window.Android.startGoogleLogin === 'function') || window.ZKInitiateStartGoogleLogin !== undefined;
    }

    public googleNativeCallback(token: string | null): void
    {
        if (token === null && this.rejected != null) {
            this.rejected(Error('Failed to retrieve id token'));
        } else if (token && this.resolved != null) {
            this.resolved(new SignInResponse(true, token));
        }
    }
}

declare global {
    interface Window {
        onGoogleLoginCallback(idToken: string | null): void;
        Android: Android;

        ZKInitiateStartGoogleLogin(): void;
    }
    interface Android {
        startGoogleLogin(): void;
    }
}

window.onGoogleLoginCallback = function (token: string | null): void {
    const signInService: GoogleService = container.resolve('googleService');
    signInService.googleNativeCallback(token);
};
