import {AndroidPurchase} from "@/models/AndroidPurchase";

export class OrderTransaction
{
    orderDate: Date;
    transactionPayload: string;

    constructor(orderDate: Date, transactionPayload: string) {
        this.orderDate = orderDate;
        this.transactionPayload = transactionPayload;
    }

    static createFromAndroidData(purchases: AndroidPurchase[], forSelf: boolean): OrderTransaction {
        const date = new Date(purchases[0].purchaseTime);
        const data = {
            'platform': 'android',
            'purchases': purchases,
            'forSelf': forSelf,
        };
        return new OrderTransaction(date, btoa(JSON.stringify(data)));
    }
}
