import {BaseEntity} from "@/models/BaseEntity";
import {MembershipInvitation} from "@/models/MembershipInvitation";

export class Membership extends BaseEntity {
    public startDate: string | null;
    public duration: string | null;
    public invitation: MembershipInvitation | null;

    constructor(id: number | null, startDate: string | null, duration: string | null, invitation: MembershipInvitation | null) {
        super(id, `/api/memberships/${id}`);
        this.startDate = startDate;
        this.duration = duration;
        this.invitation = invitation;
    }

    static empty(id?: number): Membership {
        return new Membership(id as number, null, null, null);
    }
}
