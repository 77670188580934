import { BaseEntity } from "@/models/BaseEntity";
import { User } from '@/models/User';
import { Portfolio } from '@/models/Portfolio';
import { InvitationStatus } from '@/enums/InvitationStatus';

export class Contact extends BaseEntity {
    portfolio: Portfolio | null;
    user: User | null;
    email: string;
    invitationStatus: InvitationStatus;
    updatedAt: Date;
    createdAt: Date;

    constructor (id: number, idPath: string, portfolio: Portfolio | null, user: User | null, email: string, invitationStatus: InvitationStatus, updatedAt: Date, createdAt: Date) {
        super(id, idPath);

        this.portfolio = portfolio;
        this.user = user;
        this.email = email;
        this.invitationStatus = invitationStatus;
        this.updatedAt = updatedAt;
        this.createdAt = createdAt;
    }
}
