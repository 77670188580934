import {BaseEntity} from "@/models/BaseEntity";

export class MembershipInvitation extends BaseEntity {
    public name: string;
    public email: string;
    public readonly hash: string | null;

    constructor(id: number | null, name: string, email: string, hash: string | null) {
        super(id, `/api/membership_invitations/${id}`);
        this.name = name;
        this.email = email;
        this.hash = hash;
    }

    public get invited(): boolean {
        return !!this.hash;
    }

    public get iriRequestObject(): any {
        return {
            name: this.name,
            email: this.email,
        };
    }

    static empty(id?: number): MembershipInvitation {
        return new MembershipInvitation(id as number, '', '', null);
    }
}
