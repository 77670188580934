import { BaseEntity } from "@/models/BaseEntity";
import {Post} from '@/models/Post';
import { User } from '@/models/User';

export class Portfolio extends BaseEntity {
    owner: User;
    startDate: Date;
    posts: Post[];
    updatedAt: Date;
    createdAt: Date;

    constructor(id: number, idPath: string, owner: User, startDate: Date, posts: Post[], updatedAt: Date, createdAt: Date) {
        super(id, idPath);
        this.owner = owner;
        this.startDate = startDate;
        this.posts = posts;
        this.updatedAt = updatedAt;
        this.createdAt = createdAt;
    }

    static fromIRIRef(ref: string): Portfolio | null {
        const match = ref.match(/\/portfolios\/(\d+)/);
        if (match && match.length > 1) {
            return this.empty(Number(match[1]));
        }
        return null;
    }

    static empty(id?: number): Portfolio {
        return new Portfolio(id as number, '', new User(-1, '', '', '', null), new Date(), [], new Date(), new Date());
    }
}
