import { BaseEntity } from "@/models/BaseEntity";
import {User} from "@/models/User";

export class Comment extends BaseEntity {
    message: string;
    author: User;
    post: string | null; // IRI usage.
    createdAt: Date;
    updatedAt: Date;
    isEditing: boolean = false;

    constructor (id: number | null, message: string, post: string, author: User, createdAt: Date, updatedAt: Date) {
        super(id, '');

        this.post = post;
        this.message = message;
        this.author = author;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    public get iriRequestObject(): any {
        const object = Object.assign({}, this) as any;
        object['author'] = this.author.idPath;
        return object;
    }

    public get iriPath(): string {
        return `/api/posts/${this.id}`;
    }
}
