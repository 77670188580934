import { User } from '@/models/User';
import { NewUser } from '@/models/NewUser';
import HttpClient from '@/util/httpClient';
import {LoginComponentData} from "@/models/interfaces/LoginComponentData";
import PlainApiResponse from "@/models/PlainApiResponse";

export class UserService {
    protected client: HttpClient;

    public constructor (opts: any) {
        this.client = opts.httpClient;
    }

    public static parseUser(data: any | null): User {
        return new User(
            data.id,
            data['@id'],
            data.email,
            data.name,
            data.birthdate,
        );
    }

    public async userExists (userEmail: string): Promise<boolean> {
        const url: string = 'user/exists';
        const existsData: any = {'email': userEmail};
        const response: any = await this.client.post(url, existsData, null);

        if (response != null && response.data != null && response.data['exists']) {
            return response.data['exists'];
        }

        return false;
    }

    public async registerNewUser(user: NewUser, platform: string | null = null): Promise<boolean> {
        const url: string = 'user/register';
        const data = user as any;
        if (platform) {
            data['platform'] = platform;
        }
        const response: any = await this.client.post(url, data, null);

        if (response != null && response.data != null && response.data['success']) {
            return response.data['success'];
        }

        return false;
    }

    public async updateExistingUser(user: NewUser): Promise<boolean> {
        const url: string = 'user/update';
        const response: any = await this.client.post(url, user, null);

        if (response != null && response.data != null && response.data['success']) {
            return response.data['success'];
        }

        return false;
    }

    public async deleteUser(): Promise<boolean> {
        const deleteUrl: string = 'user/delete';
        const response: any = await this.client.delete(deleteUrl);
        return response.status === 204;
    }

    public async currentUser(): Promise<User | null> {
        const response = await this.client.fetch('user/me', { withCredentials: true });
        if (response.status == 200 && response.data) {
            const data = response.data;
            return User.createFromData(data);
        }
        return null;
    }

    public async login(data: LoginComponentData): Promise<User | null> {
        const postBody = {
            email: data.email,
            password: data.password,
        };

        const response = await this.client.post('user/login', postBody, {withCredentials: true});
        if (response.status == 200 && response.data) {
            const currentUserResponse = await this.currentUser();
            if (currentUserResponse) {
                return currentUserResponse;
            }
        }

        return null;
    }

    public async facebookLogin(token: string): Promise<User | null> {
        const postBody = JSON.stringify({ accessToken: token });
        const response = await this.client.post('user/facebook-login', postBody, { withCredentials: true });
        if (response.status == 200 && response.data) {
            const currentUserResponse = await this.currentUser();
            if (currentUserResponse) {
                return currentUserResponse;
            }
        }
        return null;
    }

    public async googleLogin(token: string | null): Promise<User | null> {
        const postBody = JSON.stringify({ access_token: token });
        const response = await this.client.post('user/google-login', postBody, { withCredentials: true });
        if (response.status == 200 && response.data) {
            const currentUserResponse = await this.currentUser();
            if (currentUserResponse) {
                return currentUserResponse;
            }
        }
        return null;
    }

    public async fetchGoogleUserData(token: string | null): Promise<any> {
        const response = await this.client.fetch(
            'https://www.googleapis.com/oauth2/v2/userinfo?access_token=' + token,
            { withCredentials: false },
            true,
        );
        if (response.status === 200) {
            if (response.data) {
                return response.data;
            }
        }
        return null;
    }

    public async fetchFacebookUserData(token: string | null): Promise<any> {
        const response = await this.client.fetch(
            'https://graph.facebook.com/me?fields=email,name&access_token=' + token,
            { withCredentials: false },
            true,
        );
        if (response.status === 200) {
            if (response.data) {
                return response.data;
            }
        }
        return null;
    }

    public async logout(): Promise<boolean> {
        const response = await this.client.fetch('user/logout', null);
        return response.status === 200;
    }

    public async requestPasswordReset(email: string | null): Promise<PlainApiResponse> {
        const response = await this.client.post('reset-password/api', { email: email }, [], true);
        const data = response.data;
        if (response.status === 200 && data) {
            return new PlainApiResponse(data);
        }
        return new PlainApiResponse({ data: null, message: data.message, success: data.success });
    }
}
