export async function callAndroidAsync(javaFuncName: string, params: any): Promise<string> {
    if (!window.AndroidCallbackManager) {
        window.AndroidCallbackManager = {};
    }
    const rand: string = 'asyncJava_' + Math.floor(Math.random() * 1000000) as string;
    window.AndroidCallbackManager[rand] = {};

    // func called from android
    window.AndroidCallbackManager[rand].callback = (isSuccess: boolean) => {
        const dataOrErr = window.Android.runAsyncResult(rand);
        if (isSuccess) window.AndroidCallbackManager[rand].resolve(dataOrErr);
        else window.AndroidCallbackManager[rand].reject(dataOrErr);
        delete window.AndroidCallbackManager[rand]; // clean up
    };

    window.Android.runAsync(rand, javaFuncName, params ? JSON.stringify(params) : null);

    return new Promise((resolve, reject) => {
        window.AndroidCallbackManager[rand].resolve = (data: string) => resolve(data);
        window.AndroidCallbackManager[rand].reject = (err: string) => reject(err);
    });
}